import { useState, useEffect } from "react";
import { useWindowWidth } from "../../../shared/helper";
import FullScreenModal from "../layouts/fullscreen-modal/FullscreenModal";
import InvoiceCard from "../layouts/mobile/invoice-card/InvoiceCard";
import InvoicesList from "../layouts/mobile/invoices-list/InvoicesList";
import ShowAll from "../layouts/helper/ShowAll";
import Invoices from "./invoices/Invoices";
import { getInvoicesByCustomer } from "../../services/profile-page-api";
import { getAllResponses } from "../../../beta-home/services/network/get-all-responses-api";
import { getCustomerById } from "../../services/profile-page-api";
import { useFetchPaymentStatus } from "./invoices/useFetchPaymentStatus";
import { getFormByFormId } from "../../../beta-home/services/network/get-form-by-formId";

import useDataFetcher from "../../../beta-home/services/helper/useDataFetcher";
import { CircularProgress } from "@mui/material";

export default function InvoicesWrapperCard({ formId }) {
  const [showModal, setShowModal] = useState(false);

  const { isMobile } = useWindowWidth();

  const handleShowAll = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const {
    data: invoicesData,
    loading: loadingInvoices,
    error,
  } = useDataFetcher({
    getFunction: getInvoicesByCustomer,
  });

  const invoices = invoicesData ? invoicesData?.invoices : [];

  const {
    data: responses,
    loading: loadingResponses,
    error: errorFetchingResponses,
    ErrorSnackBar,
  } = useDataFetcher({
    getFunction: getAllResponses,
    responsesApi: true,
    params: formId,
  });

  const { data: memberData, loading: loadingMemberInfo } = useDataFetcher({
    getFunction: getCustomerById,
  });

  const memberInfo = memberData?.customer;

  const {
    data: formData,
    loading,
    error: errorFetchingForm,
  } = useDataFetcher({
    getFunction: getFormByFormId,
    params: formId,
  });

  const { invoiceIdArray: pendingVerificationInvoiceId, addPaymentInvoiceId } =
    useFetchPaymentStatus({
      formData,
      responses,
      memberInfo,
    });

  return (
    <>
      {loading || loadingResponses || loadingMemberInfo || loadingInvoices ? (
        <div className="loader-wrapper">
          {" "}
          <CircularProgress />
        </div>
      ) : (
        <div className="invoices-wrapper-card">
          <div className="title-show-all">
            <div className="card-title-bold">Payments</div>
            {invoices?.length > 3 && <ShowAll handleClick={handleShowAll} />}
          </div>
          {invoices.length === 0 ? (
            <div className="empty-list">No invoices available!</div>
          ) : isMobile ? (
            <InvoiceCard
              isRecent={true}
              formId={formId}
              memberInfo={memberInfo}
              invoicesData={invoices}
              pendingVerificationInvoiceId={pendingVerificationInvoiceId}
              addPaymentInvoiceId={addPaymentInvoiceId}
            />
          ) : (
            <Invoices
              isRecent={true}
              formId={formId}
              memberInfo={memberInfo}
              invoicesData={invoices}
              pendingVerificationInvoiceId={pendingVerificationInvoiceId}
              addPaymentInvoiceId={addPaymentInvoiceId}
            />
          )}
          {showModal && (
            <FullScreenModal
              onClose={handleClose}
              title={"Invoices"}
              child={
                isMobile ? (
                  <InvoicesList
                    formId={formId}
                    memberInfo={memberInfo}
                    invoicesData={invoices}
                    pendingVerificationInvoiceId={pendingVerificationInvoiceId}
                    addPaymentInvoiceId={addPaymentInvoiceId}
                  />
                ) : (
                  <Invoices
                    formId={formId}
                    memberInfo={memberInfo}
                    invoicesData={invoices}
                    pendingVerificationInvoiceId={pendingVerificationInvoiceId}
                    addPaymentInvoiceId={addPaymentInvoiceId}
                  />
                )
              }
            />
          )}
        </div>
      )}
      {(error || errorFetchingResponses || errorFetchingForm) &&
        ErrorSnackBar()}
    </>
  );
}
