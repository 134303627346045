import styles from "./TryApp.module.css";
import logo from "../../../../shared/assets/yogawavLogo.svg";

export default function TryApp() {
  return (
    <section className={styles.section}>
      <div className={styles.text}>
        <span className={styles.tryApp}>
          Give{" "}
          <span className={styles.app}>
            <img className={styles.image} alt="" src={logo} /> Yogawav
          </span>{" "}
          a Try
        </span>
        <span>It Could be Just What You're Looking For!</span>
      </div>
      <div className={styles.button}>
        <button className={styles.bgBlueBtn}>Try for Free</button>
      </div>
    </section>
  );
}
