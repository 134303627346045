import { forwardRef, useState, useRef } from "react";
import styles from "./PaymentsModal.module.css";
import { IoMdClose } from "react-icons/io";
import SubmissionConfirmationPopup from "../submission-confirmation-popup/SubmissionConfirmationPopup";
import { getFormByFormId } from "../../../../beta-home/services/network/get-form-by-formId";
import { getPaymentInfo } from "../../../../beta-home/services/network/get-payment-info";

import { Snackbar, Alert } from "@mui/material";
import useDataFetcher from "../../../../beta-home/services/helper/useDataFetcher";
import useSubmitResponse from "../../../../beta-home/services/helper/useSubmitResponse";
import useFormHandler from "../../../../beta-home/services/helper/useFormHandler";

const PaymentsModal = forwardRef(
  (
    {
      formId,
      invoice,
      memberInfo,
      handleClose,
      onPaymentSuccess,
      onSubmissionConfirmationPopupClose,
    },
    ref
  ) => {
    const fileInputRef = useRef(null);

    const submissionPopupIdentifiers = [];

    const {
      formRef,
      formData: paymentFormData,
      submissionConfirmationRef,
      fileUploadResponse,
      setFileUploadResponse,
      fileMissing,
      setFileMissing,
      handleFileUploadInputChange,
      handleRefNumberChange,
    } = useFormHandler({
      formId,
      submissionPopupIdentifiers,
    });

    function handleUploadClick() {
      fileInputRef.current.click();
    }
    function handleCancel() {
      fileInputRef.current.value = null;
      setFileUploadResponse((prevValue) => ({ ...prevValue, file_url: "" }));
    }

    const { data, loading, ErrorSnackBar } = useDataFetcher({
      getFunction: getPaymentInfo,
    });

    const paymentInfo = data?.payment_info;

    function handleModalClose() {
      if (ref.current) {
        ref.current.close();
        clearResponses();
      }
    }

    function handleCloseButtonClick() {
      handleClose();
      clearResponses();
    }

    const {
      handleSubmit,
      clearResponses,
      submissionError,
      setSubmissionError,
    } = useSubmitResponse({
      formData: paymentFormData,
      formResponses: [],
      formRef,
      fileInputRef,
      handleModalClose,
      fileUploadResponse,
      setFileUploadResponse,
      setFileMissing,
      submissionConfirmationRef,
      invoice,
      memberInfo,
      onPaymentSuccess,
    });

    return (
      <dialog className={styles.paymentModal} ref={ref}>
        <Payments
          paymentInfo={paymentInfo}
          onClose={handleCloseButtonClick}
          closeButton
        />
        <form ref={formRef} onSubmit={handleSubmit}>
          {paymentFormData &&
            paymentFormData.questions.map((question, index) => {
              if (question.type === "FILE_UPLOAD" && question.visibility) {
                return (
                  <div key={index}>
                    <input
                      placeholder="Enter payment reference number"
                      name={question._id}
                      type="text"
                      className={styles.refNumber}
                      onChange={handleRefNumberChange}
                      // required={question.is_required}
                      maxLength="20"
                    />
                    <div className={styles.upload}>
                      {fileUploadResponse.file_url ? (
                        <div className={styles.previewImage}>
                          <IoMdClose
                            className={styles.close}
                            onClick={handleCancel}
                          />
                          <img
                            className={styles.image}
                            src={fileUploadResponse.file_url}
                            alt="Uploaded Screenshot"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        className={styles.uploadFiles}
                        name={question._id}
                        onChange={handleFileUploadInputChange}
                        // required
                        accept="image/*"
                      />
                      <label
                        onClick={handleUploadClick}
                        className={styles.uploadButton}
                      >
                        {fileUploadResponse.file_url
                          ? "Change Image"
                          : "Upload Image"}
                      </label>
                      <p
                        className={`${styles.text} ${
                          fileMissing && `${styles.errorText}`
                        }`}
                      >
                        *Upload your payment screenshot (max size: 5 MB)
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          <div className={styles.submit}>
            <button>SUBMIT</button>
          </div>
        </form>
        <SubmissionConfirmationPopup
          title={"Your Payment is under verification"}
          description={
            "After your payment is confirmed, you'll be able to join the batch"
          }
          identifiers={["Name", "Invoice Number", "Amount", "Payment Status"]}
          details={[
            memberInfo?.name,
            invoice?.invoice_number,
            `${invoice?.currency_model === "INR" ? "₹" : "$"}` +
              invoice?.total_amount,
            "Pending Verification",
          ]}
          okButton
          onClose={onSubmissionConfirmationPopupClose}
          ref={submissionConfirmationRef}
        />

        <Snackbar
          open={submissionError}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => setSubmissionError(false)}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Error submitting form! Please try again.
          </Alert>
        </Snackbar>
        {ErrorSnackBar()}
      </dialog>
    );
  }
);

export function Payments({
  paymentInfo,
  onClose,
  closeButton,
  isNewMemberForm,
  templateClassName,
}) {
  return (
    <div
      className={`${styles.payments} ${
        isNewMemberForm ? styles.newMemberFormPayments : ""
      } ${styles[templateClassName || ""]}`}
    >
      {closeButton ? (
        <div className={styles.modalHeader}>
          <div className={styles.title}>Pay using the details given below</div>
          <div className={styles.closeButton} onClick={onClose}>
            <IoMdClose />
          </div>
        </div>
      ) : (
        <div className={styles.title}>Payments</div>
      )}
      <div className={styles.paymentDetailsCard}>
        {paymentInfo?.qr_code_url ? (
          <>
            {" "}
            <div className={styles.scan}>
              <div>Scan QR code</div>
              <div className={styles.scanImage}>
                <img src={paymentInfo ? paymentInfo.qr_code_url : ""} />
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.or}>OR</div>
          </>
        ) : (
          ""
        )}
        <div
          className={styles.bankDetails}
          style={{ width: !paymentInfo?.qr_code_url ? "100%" : "" }}
        >
          <div>Bank account details</div>
          <div className={styles.details}>
            <ul className={styles.labels}>
              {paymentInfo?.account_number && <li>Account Number:</li>}
              {paymentInfo?.ifsc_code && <li>IFSC Code:</li>}
              {paymentInfo?.account_holder_name && <li>Account Holder:</li>}
            </ul>
            <ul className={styles.values}>
              <li>{paymentInfo?.account_number}</li>
              <li>{paymentInfo?.ifsc_code}</li>
              <li>{paymentInfo?.account_holder_name}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsModal;
