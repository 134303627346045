export default function getLandingPageData(pageData = {}) {
  // pageData = pageData || {};

  const {
    welcome_section = {},
    owner = {},
    business = {},
    sales = {},
    gallery = [],
    social_media_links = {},
    theme = {},
    services_and_benefits = [],
    testimonials = [],
    why_us = [],
    form_ids = {},
    user_db_id = {},
    subdomain = {},
    user_id = {},
  } = pageData;

  return {
    welcomeSection: {
      title: welcome_section.title || "",
      aboutUs: welcome_section.about_us || "",
      image: welcome_section.image_url || "",
    },
    ownerInfo: {
      name: owner.name || "",
      email: owner.email || "",
      mobileNumber: owner.mobile_number || "",
    },
    business: {
      name: business.name || "",
      logo: business.logo || "",
      address: business.address || "",
      email: business.email || "",
      mobileNumber: business.mobile_number || "",
    },
    salesPerson: {
      whatsappNumber: sales.whatsapp_number || "",
      mobileNumber: sales.mobile_number || "",
      email: sales.email_address || "",
    },
    gallery,
    socialLinks: {
      facebook: social_media_links.facebook_link || "",
      instagram: social_media_links.instagram_link || "",
      twitter: social_media_links.twitter_link || "",
      youtube: social_media_links.youtube_link || "",
      linkedin: social_media_links.linkedIn_link || "",
    },
    theme: {
      color: theme.color || "",
      fontStyle: theme.font_style || "",
      template: theme.template || "default",
    },
    servicesList: services_and_benefits,
    whyUsList: why_us,
    testimonialsList: testimonials,
    formIds: {
      leadFormId: form_ids.lead_form_id,
      newMemberFormId: form_ids.new_member_form_id,
      manualPaymentFormId: form_ids.manual_payment_form_id,
      appointmentFormId: form_ids.appointment_form_id,
    },
    userdbId: user_db_id,
    subdomain: subdomain,
    userId: user_id,
  };
}
