import { forwardRef } from "react";
import styles from "./AccountPopup.module.css";
import { IoMdClose } from "react-icons/io";
import { setMemberDataLocal, formatDate } from "../../../../shared/helper";

import { googleLogout } from "@react-oauth/google";
import blankProfile from "../../../assets/blank_profile.png";
import { CircularProgress } from "@mui/material";

import useDataFetcher from "../../../../beta-home/services/helper/useDataFetcher";
import { getCustomerById } from "../../../services/profile-page-api";
import getLandingPageData from "../../../../beta-home/services/helper/get_landing_page_data";

const AccountPopup = forwardRef(function AccountPopup(
  { onClose, memberData, setMemberData },
  ref
) {
  const landingPageData = getLandingPageData(window.pageData);

  const { subdomain, business } = landingPageData;

  function handleGoogleLogout() {
    googleLogout();
    setMemberDataLocal();

    if (window.location.hostname.includes("localhost")) {
      window.location.replace(
        `http://localhost:${window.location.port}/auth?sd=${subdomain}&name=${business.name}&logo=${business.logo}`
      );
    } else {
      // window.location.href = `https://yogawav.com/auth?sd=${subdomain}`;
      window.location.replace(
        `https://yogawav.com/auth?sd=${subdomain}&name=${business.name}&logo=${business.logo}`
      );
    }
  }

  const { data, loading } = useDataFetcher({
    getFunction: getCustomerById,
  });

  const memberInfo = data?.customer;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.mainContainer} ref={ref}>
        <IoMdClose className={styles.closeBtn} onClick={onClose} />
        <div className={styles.popupHeader}>Account Info</div>
        {loading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.details}>
            {memberInfo?.image ? (
              <img src={memberInfo?.image} alt="" />
            ) : (
              <img src={blankProfile} />
            )}
            {memberInfo?.name && (
              <span className={styles.name}>{memberInfo?.name}</span>
            )}
            {memberInfo?.customer_id && (
              <span>Member ID: {memberInfo?.customer_id}</span>
            )}
            {memberInfo?.registered_on && (
              <span>Joined on: {formatDate(memberInfo.registered_on)}</span>
            )}
            {memberInfo?.email && <span>{memberInfo?.email}</span>}
          </div>
        )}
        <button className={styles.signoutBtn} onClick={handleGoogleLogout}>
          Sign Out
        </button>
      </div>
    </div>
  );
});

export default AccountPopup;
