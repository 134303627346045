import logo from "../../../../shared/assets/yogawavLogo.svg";

import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import styles from "./Footer.module.css";

export default function Footer() {
  const navigateSocialIcon = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.brand}>
          <div className={styles["brand-logo"]}>
            <img src={logo} alt="" />
            <span className={styles.textBold}>Yogawav</span>
          </div>
          <div className={styles.footerLinks}>
            <span className={styles.textLight}>
              Powered By NucleApp Pvt Ltd
            </span>
            <div className={styles.textMedium}>Quick Links</div>
            <ul className={styles.quickLinks}>
              <li className={styles.link}>Terms of Services</li>
              <li className={styles.link}>Privacy Policy</li>
              <li className={styles.link}>Refund & Cancellation Policy</li>
            </ul>
          </div>
        </div>
        <div className={styles.address}>
          <span className={styles.title}>Address</span>
          <pre>
            {
              "NucleApp Software Private Limited \nAwfis Space Solutions, \nKondapur \nHyderabad, Telangana, \nIndia -500084."
            }
          </pre>
          <a href="mailto:support@nucleapp.com">support@nucleapp.com</a>
        </div>

        <div className={styles.social}>
          <p>Know us better</p>
          <div className={styles.socialIcons}>
            <FaFacebook
              onClick={() =>
                navigateSocialIcon(
                  "https://www.facebook.com/profile.php?id=61573569298178"
                )
              }
            />
            <FaInstagram
              onClick={() =>
                navigateSocialIcon(" https://www.instagram.com/yogawav_/")
              }
            />
            <FaYoutube
              onClick={() =>
                navigateSocialIcon("https://www.youtube.com/@Nucleapp")
              }
            />
            <FaLinkedin
              onClick={() =>
                navigateSocialIcon(
                  "https://www.linkedin.com/showcase/yogawav/ "
                )
              }
            />
            <FaXTwitter
              onClick={() => navigateSocialIcon("https://x.com/yogawav")}
            />
          </div>
          <div className={styles.footerLinksMobile}>
            <div className={styles.textMedium}>Quick Links</div>
            <ul className={styles.quickLinks}>
              <li className={styles.link}>Terms of Services</li>
              <li className={styles.link}>Privacy Policy</li>
              <li className={styles.link}>Refund & Cancellation Policy</li>
            </ul>
            <span className={styles.textLight}>Powered By NucleApp</span>
          </div>
        </div>
      </div>
    </div>
  );
}
