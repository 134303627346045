import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import stylesTemplate1 from "./templates/template-1/Gallery.module.css";
import stylesTemplate2 from "./templates/template-2/Gallery.module.css";

import { useEffect, useState, useRef, forwardRef } from "react";
import { useTheme } from "../../ThemeProvider.jsx";

const Gallery = forwardRef(function Gallery({ photosList, template }, ref) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const timer = useRef(null);

  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "edgefusion" ||
    templateName === "simpleui" ||
    templateName === "elevate"
      ? stylesTemplate2
      : stylesTemplate1;

  // useEffect(() => {
  //   timer.current =
  //     autoPlay &&
  //     setTimeout(() => {
  //       slideRight();
  //     }, 10000);
  // });

  useEffect(() => {
    if (autoPlay) {
      timer.current = setTimeout(slideRight, 4000);
    }
    return () => clearTimeout(timer.current);
  }, [autoPlay, currentIndex]);

  function slideLeft() {
    if (currentIndex === 0) {
      setCurrentIndex(photosList.length - 1);
    } else {
      setCurrentIndex((prevIndex) => {
        return prevIndex - 1;
      });
    }
  }

  function slideRight() {
    if (currentIndex === photosList.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => {
        return prevIndex + 1;
      });
    }
  }

  const prevIndex =
    currentIndex === 0 ? photosList.length - 1 : currentIndex - 1;
  const nextIndex =
    currentIndex === photosList.length - 1 ? 0 : currentIndex + 1;

  return (
    <div
      ref={ref}
      className={`${styles.gallery} ${styles[templateClassName]}`}
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timer.current);
      }}
      id="gallery"
      onMouseLeave={() => setAutoPlay(true)}
    >
      <div className={styles.titleHeader}>
        <h2>Gallery</h2>
      </div>
      <div className={styles.carouselWrapper}>
        <div className={styles.prevImage}>
          <img src={photosList[`${prevIndex}`]} />
        </div>
        {photosList.map((image, index) => {
          return (
            <div
              key={`image-${index}`}
              className={`${styles.carouselCard} ${
                index === currentIndex ? styles.carouselCardActive : ""
              } ${index === prevIndex ? styles.carouselCardPrev : ""}`}
            >
              <img src={image} alt="" />
            </div>
          );
        })}

        <div className={styles.nextImage}>
          <img src={photosList[`${nextIndex}`]} />
        </div>

        {photosList.length > 1 && (
          <>
            <div className={styles.arrow}>
              <div className={styles.leftArrow} onClick={slideLeft}>
                <IoIosArrowBack />
              </div>
              <div className={styles.rightArrow} onClick={slideRight}>
                <IoIosArrowForward />
              </div>
            </div>

            <div className={styles.carouselPagination}>
              {photosList.map((_, index) => {
                return (
                  <div
                    key={`page-${index}`}
                    className={`${styles.paginationDot} ${
                      index === currentIndex ? styles.paginationDotActive : ""
                    }`}
                    onClick={() => {
                      setCurrentIndex(index);
                    }}
                  ></div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default Gallery;
