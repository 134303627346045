import styles from "./Invoices.module.css";
import { useState, useEffect, useRef } from "react";
import { formatDate } from "../../../../shared/helper";
import PaymentsModal from "../payment-modal/PaymentsModal";
import { useNavigate } from "react-router-dom";

export default function Invoices({
  isRecent,
  formId,
  pendingVerificationInvoiceId,
  memberInfo,
  invoicesData,
  addPaymentInvoiceId,
}) {
  const invoices = isRecent ? invoicesData.slice(-3) : invoicesData;
  return (
    <>
      <>
        {invoices && (
          <div
            className={`${styles.invoicesList} ${
              isRecent ? styles.recent : ""
            }`}
          >
            <div className={styles.headerRow}>
              <TableHeader text="Invoice" />
              <TableHeader text="Date" />
              <TableHeader title="amount" text="Amount" />
              <TableHeader title="mop" text="Mode of payment" />
              <TableHeader title="paymentBtn" text="Download / Pay" />
            </div>
            {invoices.map((invoice, index) => (
              <div className={styles.dataRow} key={index}>
                <TableData data={invoice?.invoice_number} />
                <TableData data={formatDate(invoice?.date)} />
                <TableData
                  title="amount"
                  data={`
                  ${invoice?.currency_model === "INR" ? "₹" : "$"} ${
                    invoice?.total_amount
                  }`}
                />
                <TableData title={"mop"} data={invoice?.mode_of_payment} />
                <TableData
                  title="paymentBtn"
                  data="payment"
                  formId={formId}
                  paymentStatus={invoice?.payment_status}
                  invoice={invoice}
                  memberInfo={memberInfo}
                  addPaymentInvoiceId={addPaymentInvoiceId}
                  pendingVerificationInvoiceId={pendingVerificationInvoiceId}
                />
              </div>
            ))}
          </div>
        )}
      </>
    </>
  );
}

function TableHeader({ text, title }) {
  return <div className={`${styles.tableHeader} ${styles[title]}`}>{text}</div>;
}

function TableData({
  data,
  title,
  paymentStatus,
  formId,
  invoice,
  memberInfo,
  addPaymentInvoiceId,
  pendingVerificationInvoiceId,
}) {
  return (
    <div className={`${styles.tableData} ${styles[title]}`}>
      {data !== "payment" ? (
        data
      ) : (
        <Payment
          formId={formId}
          paymentStatus={paymentStatus}
          invoice={invoice}
          invoiceId={invoice?._id}
          memberInfo={memberInfo}
          addPaymentInvoiceId={addPaymentInvoiceId}
          pendingVerificationInvoiceId={pendingVerificationInvoiceId}
        />
      )}
    </div>
  );
}

function Payment({
  paymentStatus,
  formId,
  invoice,
  memberInfo,
  addPaymentInvoiceId,
  pendingVerificationInvoiceId,
}) {
  const payNowModal = useRef();

  const [selectedInvoice, setSelectedInvoice] = useState();

  const navigate = useNavigate();

  function handlePayNowBtnClick(invoice) {
    setSelectedInvoice(invoice);
  }

  function handlePayNowModalClose() {
    payNowModal.current.close();

    setSelectedInvoice(null);
  }

  useEffect(() => {
    if (selectedInvoice && payNowModal.current) {
      payNowModal.current.showModal();
    }
  }, [selectedInvoice]);

  function handlePaymentSuccess() {
    addPaymentInvoiceId(selectedInvoice._id);
  }

  function handleSubmissionConfirmationPopupClose() {
    setSelectedInvoice(null);
  }

  function navigateInvoicePage(id) {
    navigate(`/invoice?${new URLSearchParams({ id })}`);
  }

  return (
    <div className={styles.payment}>
      {pendingVerificationInvoiceId.includes(invoice._id) &&
      paymentStatus !== "PAID" ? (
        <button className={styles.pending}>Pending Verification</button>
      ) : invoice.payment_status === "PAID" ? (
        <button
          className={styles.downloadReceipt}
          onClick={() => navigateInvoicePage(invoice._id)}
        >
          Download Receipt
        </button>
      ) : (
        <button
          className={styles.payNow}
          onClick={() => handlePayNowBtnClick(invoice)}
        >
          Pay now
        </button>
      )}
      {formId && paymentStatus === "DUE" ? (
        <PaymentsModal
          ref={payNowModal}
          formId={formId}
          invoice={selectedInvoice}
          memberInfo={memberInfo}
          handleClose={handlePayNowModalClose}
          onPaymentSuccess={handlePaymentSuccess}
          onSubmissionConfirmationPopupClose={
            handleSubmissionConfirmationPopupClose
          }
        />
      ) : (
        ""
      )}
    </div>
  );
}
