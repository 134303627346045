import { ShowAll } from "../../../../shared/components/utils/utils";

import stylesTemplate1 from "./templates/template-1/Batches.module.css";
import stylesTemplate2 from "./templates/template-2/Batches.module.css";

import { forwardRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef } from "react";
import { getAllBatches } from "../../../services/network/get-batches-api.js";
import CircularProgress from "@mui/material/CircularProgress";
import BatchesModal from "../modals/BatchesModal/batches-modal/BatchesModal.jsx";

import { useTheme } from "../../ThemeProvider.jsx";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaClock, FaLocationDot } from "react-icons/fa6";
import { MdTimer } from "react-icons/md";
import { Snackbar, Alert } from "@mui/material";
import { BsExclamationCircle } from "react-icons/bs";
import { formatTime } from "../../../../shared/helper.js";
import { formatDate } from "../../../../shared/helper.js";
import ContactUsModal from "../../../../member-dashboard/components/layouts/contact-us-modal/ContactUsModal.jsx";
import useDataFetcher from "../../../services/helper/useDataFetcher.jsx";

const Batches = forwardRef(function Batches(
  { isBatchesPage, formId, batchFormId, businessDetails },
  ref
) {
  const { templateName, templateClassName } = useTheme();

  const styles = templateName === "default" ? stylesTemplate1 : stylesTemplate2;

  let className = `${styles.batches} ${styles[templateClassName]}`;
  if (isBatchesPage) {
    className += ` ${styles.batchesPage}`;
  } else {
    className += "";
  }

  const navigate = useNavigate();

  const { data, loading, ErrorSnackBar } = useDataFetcher({
    getFunction: getAllBatches,
  });

  const batchesData = data?.batches ? data.batches : [];

  const location = useLocation();

  const navigateBatchesPage = () => {
    const searchParams = new URLSearchParams(location.search);
    navigate(`/batches?${searchParams}`);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: templateName === "midnightzen" ? "black" : "white",
            paddingTop:
              templateName === "simpleui" && isBatchesPage ? "130px" : "50px",
            paddingBottom:
              templateName === "simpleui" && isBatchesPage ? "50px" : "30px",
          }}
          className="styles.loaderWrapper"
        >
          <CircularProgress thickness={4} />
        </div>
      ) : (
        <div id="batches" className={className}>
          <>
            {isBatchesPage ? (
              <>
                <div className={styles.titleHeader}>
                  <div>Batches</div>
                </div>
                <ul className={styles.batchList}>
                  {batchesData.map((batch, index) => {
                    return (
                      <li key={`batch-${index}`}>
                        <BatchCard
                          batchData={batch}
                          isBatchesPageCard={isBatchesPage}
                          formId={batchFormId}
                          businessDetails={businessDetails}
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : batchesData.length > 0 ? (
              <>
                <div className={styles.titleHeader}>
                  <h2>Batches</h2>
                  <span
                    className={`${
                      batchesData.length > 3
                        ? `${styles.showAll}`
                        : `${styles.noShowAll}`
                    }`}
                    onClick={navigateBatchesPage}
                  >
                    <ShowAll />
                  </span>
                </div>
                <ul className={styles.batchList}>
                  {batchesData.map((batch, index) => {
                    return (
                      index < 3 && (
                        <li key={`batch-${index}`}>
                          <BatchCard
                            batchData={batch}
                            isBatchesPageCard={isBatchesPage}
                            formId={formId}
                            businessDetails={businessDetails}
                          />
                        </li>
                      )
                    );
                  })}
                </ul>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      )}
      {ErrorSnackBar()}
    </>
  );
});

export function BatchCard({
  batchData,
  businessDetails,
  isBatchesPageCard,
  isMemberPage,
  formId,
}) {
  const theme = useTheme();
  const color = theme?.color || "";
  const templateName = theme?.templateName || "";
  const templateClassName = theme?.templateClassName || "";
  const [showSnackbar, setSnackbar] = useState();

  const styles =
    templateName === "default"
      ? stylesTemplate1
      : !templateName
      ? stylesTemplate1
      : stylesTemplate2;

  let className = styles.batchCard;
  if (isBatchesPageCard) {
    className += ` ${styles.batchesPage}`;
  } else if (isMemberPage) {
    className += ` ${styles.membersPage}`;
  } else {
    className += "";
  }

  const contactModalRef = useRef();

  function handleContactUsBtnClick() {
    contactModalRef.current.showModal();
  }

  const handleJoinNowBtnClick = () => {
    if (batchData?.location?.streaming_info?.link) {
      window.open(
        batchData.location.streaming_info.link,
        "_blank",
        "noopener, noreferrer"
      );
    } else {
      setSnackbar(true);
    }
  };

  const availableSeats =
    batchData?.general_details?.max_customers - batchData?.customer_count;

  const dayNames = {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
    SUN: "Sunday",
  };

  const formattedDays =
    batchData?.schedule?.days_active &&
    batchData.schedule.days_active.map((day) => dayNames[day]).join(" | ");

  const batchesModalRef = useRef();

  return (
    <div className={className}>
      <div className={styles.batchDetails}>
        <img className={styles.image} src={batchData?.image_url} alt="" />
        {availableSeats <= 0 && (
          <div className={styles.batchFull}>
            <BsExclamationCircle />
            <span className={styles.text}>Batch Full</span>
          </div>
        )}
        <div
          className={`${
            availableSeats <= 0
              ? `${styles.batchFullContent}`
              : `${styles.batchContent}`
          }`}
        >
          {" "}
          <div className={styles.title}>
            {batchData?.general_details?.title}
          </div>
          <div className={styles.desc}>
            {batchData?.general_details?.description}
          </div>
          <div className={styles.aboutBatch}>
            {batchData?.schedule?.session_frequency === "one-time" ? (
              <>
                <div>One Time: 1 class</div>
                <div>Day: {formatDate(batchData?.schedule?.date)}</div>
              </>
            ) : batchData?.schedule?.session_frequency === "weekly" ? (
              <>
                <div className={styles.weeklyClasses}>
                  Weekly: {batchData?.schedule?.days_active?.length} Classes
                </div>
                <div className={styles.days}>Days: {formattedDays}</div>
              </>
            ) : (
              <>
                <div>Monthly: 1 class</div>
                <div>Day: {formatDate(batchData?.schedule?.start_date)}</div>
              </>
            )}

            <div className={styles.time}>
              Time:{" "}
              {batchData?.schedule?.time &&
                formatTime(batchData?.schedule?.time)}
            </div>
            <div className={styles.duration}>
              Duration: {batchData?.schedule?.duration} Mins
            </div>
            <div className={styles.location}>
              Location: {batchData?.location?.mode}
            </div>
            {isMemberPage && batchData?.location?.streaming_info?.passcode && (
              <div className={styles.passcode}>
                Passcode: {batchData?.location?.streaming_info?.passcode}
              </div>
            )}
          </div>
          {isMemberPage && (
            <button
              onClick={handleJoinNowBtnClick}
              className={styles.joinNowBtn}
            >
              Join Now
            </button>
          )}
          <div className={styles.aboutBatchMobile}>
            {batchData?.schedule?.session_frequency === "one-time" ? (
              <>
                <div className={styles.sessionFrequency}>
                  {" "}
                  <FaRegCalendarAlt className={styles.icon} /> One Time: 1 class
                </div>
                <div className={`${styles.day} ${styles.onetime}`}>
                  {formatDate(batchData?.schedule?.date)}
                </div>
              </>
            ) : batchData?.schedule?.session_frequency === "weekly" ? (
              <>
                <div className={styles.sessionFrequency}>
                  <FaRegCalendarAlt className={styles.icon} />{" "}
                  {batchData?.schedule?.days_active.length} Classes/Week
                </div>
                <ul className={styles.days}>
                  {batchData?.schedule?.days_active?.map((day, index) => (
                    <li key={index} className={styles.day}>
                      {day.toLowerCase()}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <div className={styles.sessionFrequency}>
                  {" "}
                  <FaRegCalendarAlt className={styles.icon} />
                  Monthly: 1 class
                </div>
                <div className={`${styles.day} ${styles.monthly}`}>
                  {formatDate(batchData?.schedule?.start_date)}
                </div>
              </>
            )}
            <div className={styles.classDetails}>
              <div className={styles.time}>
                <FaClock className={styles.icon} />
                {formatTime(batchData?.schedule?.time)}
              </div>
              <div className={styles.duration}>
                <MdTimer className={styles.icon} />{" "}
                {batchData?.schedule?.duration} Mins
              </div>
              <div className={styles.mode}>
                <FaLocationDot className={styles.icon} />{" "}
                {batchData?.location?.mode}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          availableSeats <= 0
            ? `${styles.bookingFullDetails}`
            : `${styles.bookingDetails}`
        }
      >
        <div className={styles.availableSeats}>
          {availableSeats <= 0
            ? "*This batch is currently full. Please contact us."
            : ` *Only ${availableSeats} seats left--join now`}
        </div>
        <div className={styles.pricing}>
          <div className={styles.batchPrice}>
            <span className={styles.originalPrice}>
              INR {batchData?.pricing?.amount}
            </span>
            <span className={styles.discountPrice}>
              INR {batchData?.pricing?.payable_amount}
            </span>
          </div>
          <div>
            <ContactUsModal
              businessDetails={businessDetails}
              ref={contactModalRef}
            />

            {availableSeats <= 0 ? (
              <button
                className={styles.bookNowBtn}
                onClick={handleContactUsBtnClick}
              >
                Contact Us
              </button>
            ) : (
              <button
                onClick={() =>
                  batchesModalRef.current && batchesModalRef.current.showModal()
                }
                className={styles.bookNowBtn}
              >
                Book now
              </button>
            )}
            {!isMemberPage && (
              <BatchesModal
                templateClassName={templateClassName}
                formId={formId}
                batchData={batchData}
                ref={batchesModalRef}
              />
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSnackbar(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          No live stream link available.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Batches;
