import styles from "./ServiceRequestModal.module.css";
import { forwardRef } from "react";
import { VscClose } from "react-icons/vsc";
import { useTheme } from "../../../ThemeProvider.jsx";
import SubmissionConfirmationPopup from "../../../../../member-dashboard/components/layouts/submission-confirmation-popup/SubmissionConfirmationPopup";

import { Snackbar, Alert, TextField } from "@mui/material";

import useSubmitResponse from "../../../../services/helper/useSubmitResponse.jsx";
import useFormHandler from "../../../../services/helper/useFormHandler.jsx";

const ServiceRequestModal = forwardRef(function ServiceRequestModal(
  { formId },
  ref
) {
  const { color, templateClassName } = useTheme();

  function handleModalClose() {
    if (ref.current) {
      ref.current.close();
      clearResponses();
    }
  }

  const submissionPopupIdentifiers = ["name", "date", "time"];

  const {
    formRef,
    formData: appointmentFormData,
    submissionConfirmationRef,
    submissionConfirmationData,
    setSubmissionConfirmationData,
    responses,
    setResponses,
    handleChange,
    handleKeyDown,
    handlePaste,
  } = useFormHandler({
    submissionPopupIdentifiers,
    formId,
    formTitle: "APPOINTMENT_FORM",
  });

  const { handleSubmit, clearResponses, submissionError, setSubmissionError } =
    useSubmitResponse({
      formData: appointmentFormData,
      formResponses: responses,
      formRef,
      setResponses,
      handleModalClose,
      submissionConfirmationRef,
    });

  return (
    <dialog
      className={`${styles.serviceRequestModal} ${styles[templateClassName]}`}
      ref={ref}
    >
      <div className={styles.close} onClick={handleModalClose}>
        <button className={styles.closeButton}>
          <VscClose />
        </button>
      </div>
      <div className={styles.title}>Set up a call</div>
      <form ref={formRef} className={styles.form} onSubmit={handleSubmit}>
        {appointmentFormData &&
          appointmentFormData.questions.map((question, index) => {
            return (
              question.visibility && (
                <div className={styles.form} key={index}>
                  {question.type === "TEXT" && question.max_value > 100 ? (
                    <div className={styles.formInput}>
                      <textarea
                        placeholder={question.title}
                        maxLength={question.max_value || undefined}
                        className={styles.textarea}
                        onChange={handleChange}
                        required={question.is_required}
                        name={question._id}
                        label={question.title}
                      />
                    </div>
                  ) : question.type === "DATE" || question.type === "TIME" ? (
                    <div className={styles.formInput}>
                      <TextField
                        fullWidth
                        size="small"
                        label={question.title}
                        variant="outlined"
                        type={question.type.toLowerCase()}
                        required={question.is_required}
                        onChange={handleChange}
                        name={question._id}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  ) : (
                    <div className={styles.formInput}>
                      {" "}
                      <TextField
                        fullWidth
                        size="small"
                        label={question.title}
                        variant="outlined"
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}
                        inputProps={{
                          maxLength: question.max_value || undefined,
                          pattern:
                            question.type === "NUMBER" ? "[0-9]*" : undefined,
                          minLength:
                            question.type === "NUMBER" ? "10" : undefined,
                        }}
                        type={
                          question.type === "NUMBER"
                            ? "tel"
                            : question.type.toLowerCase()
                        }
                        required={question.is_required}
                        onChange={handleChange}
                        name={question._id}
                        className={
                          question.max_value > 100 && question.type === "TEXT"
                            ? `${styles.textarea}`
                            : ""
                        }
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        <div className={styles.button}>
          <button className={styles.submit}>Submit</button>
        </div>
      </form>
      <SubmissionConfirmationPopup
        ref={submissionConfirmationRef}
        templateClassName={templateClassName}
        buttonBgColor={color}
        title={"Your call has been scheduled"}
        description={
          "Thank you for scheduling your appointment! We look forward to connecting with you at the scheduled time"
        }
        identifiers={[
          "Name",
          submissionConfirmationData.date ? "Date" : "",
          submissionConfirmationData.time ? "Time" : "",
        ]}
        details={[
          submissionConfirmationData.name,
          submissionConfirmationData.date && submissionConfirmationData.date,
          submissionConfirmationData.time && submissionConfirmationData.time,
        ]}
        onClose={() =>
          setSubmissionConfirmationData({ name: "", date: "", time: "" })
        }
        okButton
        alignLeft={templateClassName != "midnightzen"}
      />
      <Snackbar
        open={submissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSubmissionError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
    </dialog>
  );
});

export default ServiceRequestModal;
