import React, { createContext, useContext } from "react";
import getLandingPageData from "../services/helper/get_landing_page_data";
import { createTheme } from "@mui/material";
import { ThemeProvider as FormThemeProvider } from "@mui/material";

const landingPageData = getLandingPageData(window.pageData);

const { theme } = landingPageData;

const themeColor = theme.color;

const themes = [
  {
    templateName: "default",
    color: themeColor,
    templateClassName: "defaultTheme",
  },
  {
    templateName: "midnightzen",
    color: themeColor || "#496ce6",
    templateClassName: "midnightzen",
  },
  {
    templateName: "simpleui",
    color: themeColor || "#496ce6",
    templateClassName: "simpleui",
  },
  {
    templateName: "elevate",
    color: themeColor || "#496ce6",
    templateClassName: "elevate",
  },
  {
    templateName: "edgefusion",
    color: themeColor || "#496ce6",
    templateClassName: "edgefusion",
  },
  {
    templateName: "minimalist",
    color: themeColor || "#946199",
    templateClassName: "minimalist",
  },
];

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const template = theme.template;

  const activeTheme = template
    ? themes.find((theme) => theme.templateName === template)
    : themes[0];

  const muiTheme =
    activeTheme.templateName === "midnightzen"
      ? formThemes.dark
      : formThemes.default;

  return (
    <ThemeContext.Provider value={activeTheme}>
      <FormThemeProvider theme={muiTheme}>{children}</FormThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export const formThemes = {
  default: createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "18px",
            color: "#757575",
            backgroundColor: "#fff",
            padding: "0 4px",
            "&.Mui-focused": {
              color: "#757575",
            },
            "&.Mui-disabled": { color: "#757575" },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: "18px",
            fontWeight: "400",
            paddingLeft: "10px",
            color: "#212121",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#424242",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#383838",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#383838",
            },
            "& .MuiSelect-select": {
              color: "#424242",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#212121",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#cccccc",
            },
            "&.Mui-selected": {
              backgroundColor: "#b2b2b2",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "#999999",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#212121",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#fff",
          },
        },
      },
    },
  }),
  dark: createTheme({
    typography: {
      fontFamily: "'Nunito',  sans-serif",
      color: "#fff",
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "18px",
            color: "#fff",
            backgroundColor: "inherit",
            padding: "0 4px",
            "&.Mui-focused, &.MuiFormLabel-filled": {
              color: "#fff",
              backgroundColor: "#000",
            },
            "&.Mui-disabled": { color: "#fff" },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: "#fff",
            "&.Mui-disabled": {
              color: "#fff",
              WebkitTextFillColor: "#fff",
              borderColor: "#fff",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: "18px",
            fontWeight: "400",
            paddingLeft: "10px",
            WebkitBoxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ccc",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
              backgroundColor: "inherit",
            },
            "& .MuiSelect-select": {
              color: "#fff",
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#212121",
            "&:hover": {
              backgroundColor: "#333333",
            },
            "&.Mui-selected": {
              backgroundColor: "#444444",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "#555555",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: "inherit",
            color: "#fff",
          },
          icon: {
            color: "#fff",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#212121",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              color: "#fff",
            },
            "& input[type='date']::-webkit-calendar-picker-indicator": {
              filter: "invert(1)",
              color: "#fff",
            },
            "& input[type='time']::-webkit-calendar-picker-indicator": {
              filter: "invert(1)",
              color: "#fff",
            },
          },
        },
      },
    },
  }),
};
