import { useNavigate, useLocation, replace } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useTheme } from "../../ThemeProvider.jsx";

import stylesTemplate1 from "./templates/template-1/Header.module.css";
import stylesTemplate2 from "./templates/template-2/Header.module.css";

import { FaPhoneAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import EnquiryModal from "../enquiry-form/enquiry-form-modal/EnquiryModal";

export default function Header({
  businessDetails,
  sales,
  batchesPage,
  onOpen,
  formId,
}) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "simpleui" ? stylesTemplate2 : stylesTemplate1;

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.replace("#", "");
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const showEnquiryModal = useRef(null);

  function handleEnquiryClick() {
    showEnquiryModal.current.showModal();
  }

  function handleEnquiryModalClose() {
    showEnquiryModal.current.close();
  }

  let className = `${styles.header} ${styles[templateClassName] || ""}`;

  if (batchesPage) {
    className += ` ${styles.batchesPage} `;
  } else {
    className += ` `;
  }

  const navigateBatchesPage = () => {
    const searchParams = new URLSearchParams(location.search);
    navigate(`/batches?${searchParams}`);
  };

  const navigateHomePage = () => {
    const searchParams = new URLSearchParams(location.search);
    navigate(`/?${searchParams}`);
  };

  return (
    <header className={className}>
      <div className={styles.logoTitle} onClick={() => navigate("/")}>
        <img src={businessDetails.logo} alt="" />
        <p>{businessDetails.name}</p>
      </div>
      <div>
        <span className={styles.menuIcon} onClick={onOpen}>
          <GiHamburgerMenu size={30} />
        </span>
      </div>
      <div className={styles.menu}>
        <ul>
          <li
            onClick={navigateHomePage}
            className={`${!batchesPage && styles.active}`}
          >
            Home
          </li>
          <li onClick={() => navigate("/#services")}>Services</li>
          <li onClick={() => navigate("/#whychooseus")}>Why Choose Us</li>
          <li
            onClick={navigateBatchesPage}
            className={`${batchesPage && styles.active}`}
          >
            Batches
          </li>
          <li onClick={() => navigate("/#gallery")}>Gallery</li>
          <li onClick={() => navigate("/#testimonials")}>Testimonials</li>

          <button className={styles.enquiry} onClick={handleEnquiryClick}>
            Enquiry
          </button>

          <EnquiryModal
            businessDetails={businessDetails}
            sales={sales}
            ref={showEnquiryModal}
            onClose={handleEnquiryModalClose}
            formId={formId}
          />

          <button
            className={styles.signIn}
            onClick={() => (window.location.href = "/member")}
          >
            Sign in
          </button>
          {businessDetails.mobileNumber && (
            <li className={styles.call}>
              Call at{" "}
              <span>
                <FaPhoneAlt /> {businessDetails.mobileNumber}
              </span>
            </li>
          )}
        </ul>
      </div>
    </header>
  );
}
