import { forwardRef, useRef } from "react";
import styles from "./ServiceRequest.module.css";
import ServiceRequestModal from "../modals/Service-Request-Modal/ServiceRequestModal";
import { useTheme } from "../../ThemeProvider.jsx";

function ServiceRequest({ formId }) {
  const serviceRequestModalRef = useRef(null);

  const { color, templateStyle, templateClassName } = useTheme();
  return (
    <div className={`${styles.serviceRequest} ${styles[templateClassName]}`}>
      <span className={styles.title}>Do you want us to call you back ?</span>
      <span className={styles.desc}>
        Please fix a day and time when we can call you back.
      </span>
      <button
        className={styles.button}
        onClick={() =>
          serviceRequestModalRef.current &&
          serviceRequestModalRef.current.showModal()
        }
      >
        Set up a Call
      </button>
      <ServiceRequestModal formId={formId} ref={serviceRequestModalRef} />
    </div>
  );
}

export default ServiceRequest;
