import { GrNext } from "react-icons/gr";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import "./utils.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

export function ShowAll() {
  return (
    <div id="show-all">
      <span>Show All</span>
      <MdOutlineArrowForwardIos />
    </div>
  );
}

export function ReadMore({ knowMore }) {
  if (!knowMore) {
    return (
      <div id="read-more">
        <span>Read more</span>
        <GrNext className="read-more-icon" />
      </div>
    );
  } else {
    return (
      <div id="read-more">
        <span>Know more</span>
        <MdKeyboardDoubleArrowRight className="know-more-icon" />
      </div>
    );
  }
}

export function Button({ children, bgWhite, borderNone }) {
  return (
    <div className={bgWhite ? "bgWhiteBtn" : "bgBlueBtn"}>
      <button className={borderNone ? "borderNone" : ""} id="button">
        {children}
      </button>
    </div>
  );
}
