import { BiSolidQuoteAltLeft } from "react-icons/bi";

import stylesTemplate1 from "./templates/template-1/Testimonials.module.css";
import stylesTemplate2 from "./templates/template-2/Testimonials.module.css";

import blankProfile from "../../../../member-dashboard/assets/blank_profile.png";

import { forwardRef, useRef } from "react";
import { useTheme } from "../../ThemeProvider.jsx";
import { hexToRGBA } from "../../../../shared/helper.js";

const Testimonials = forwardRef(function Testimonials(
  { testimonialsList },
  ref
) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "simpleui" ? stylesTemplate2 : stylesTemplate1;

  if (testimonialsList)
    return (
      <>
        <div
          ref={ref}
          id="testimonials"
          className={`${styles.testimonials} ${styles[templateClassName]}`}
          style={{
            backgroundColor:
              templateName === "simpleui" ? hexToRGBA(color, 0.1) : "",
          }}
        >
          <div className={styles.titleHeader}>
            <h2>Testimonials</h2>
          </div>
          <ul className={styles.testimonialsCardList}>
            {testimonialsList.map((item, index) => {
              const isLastCard =
                testimonialsList.length > 2 &&
                testimonialsList.length % 2 !== 0 &&
                index === testimonialsList.length - 1;

              const isLastTwoCards =
                testimonialsList.length > 2 &&
                testimonialsList.length % 2 === 0 &&
                (index === testimonialsList.length - 1 ||
                  index === testimonialsList.length - 2);

              return (
                <li
                  key={`testimonial-${index}`}
                  className={`${
                    isLastCard || isLastTwoCards || testimonialsList.length <= 2
                      ? styles.noBorder
                      : ""
                  }`}
                >
                  <TestimonialCard
                    imageUrl={item.image_url || ""}
                    title={item.title || ""}
                    desc={item.description || ""}
                    name={item.name || ""}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
});

export default Testimonials;

export function TestimonialCard({ imageUrl, title, desc, name, template }) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "simpleui" ? stylesTemplate2 : stylesTemplate1;

  return (
    <div className={styles.testimonialCard}>
      <div className={styles.title}>
        <span className={styles.quotation}>
          <BiSolidQuoteAltLeft />
        </span>
        <span className={styles.textBold}>{title}</span>
      </div>
      <span className={styles.desc}>{desc}</span>
      <div className={styles.studentInfo}>
        {imageUrl ? (
          <img src={imageUrl} alt="" className={styles.imgRounded} />
        ) : (
          <img src={blankProfile} alt="" className={styles.imgRounded} />
        )}
        <div className={styles.details}>
          <span className={styles.name}>{name}</span>
        </div>
      </div>
    </div>
  );
}
