import Features from "../layouts/features/Features";
import Footer from "../layouts/footer/Footer";
import GetStarted from "../layouts/landing/GetStarted";
import Header from "../layouts/header/Header";
import WhyYogawav from "../layouts/WhyYogawav/WhyYogawav";
import Plans from "../layouts/Plans/Plans";
import NucleAppProduct from "../layouts/NucleAppProduct/NucleAppProduct";
import TryApp from "../layouts/tryApp/TryApp";
import logo from "../../../shared/assets/yogawavLogo.png";

import { useRef, useEffect } from "react";
import MenuModal from "../layouts/menu/MenuModal";

export default function Home() {
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    document.title = "YogaWav";
    let link = document.querySelector("link[rel='icon']");

    link.href = logo;
  }, []);

  function handleScroll(ref) {
    modalRef.current.close();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <MenuModal
        ref={modalRef}
        pricingScroll={() => handleScroll(pricingRef)}
        featuresScroll={() => handleScroll(featuresRef)}
      />
      <main>
        <Header
          pricingScroll={() => handleScroll(pricingRef)}
          featuresScroll={() => handleScroll(featuresRef)}
          onOpen={() => modalRef.current.showModal()}
        />
        <GetStarted />
        <WhyYogawav />
        <NucleAppProduct />
        <Features ref={featuresRef} />
        <Plans ref={pricingRef} />
        <TryApp />
        <Footer />
      </main>
    </>
  );
}
